.App {
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

@keyframes slideTop {
  0% {
    top: 50%;
    margin-top: -80px;
    margin-left:-32px;
  }
  75% {
    top: 50%;
    margin-top: -80px;
    margin-left:-32px;
  }
  100% {
    top:20px;
    margin-top: 0px;
    margin-left:-16px;
  }
}

@keyframes OpacityLogo {
  0% {
    width:65px;
    height: 120px;
    opacity: 0.0;
  }
  50% {
    width: 65px;
    height: 120px;
    opacity: 1.0;
  }
  75% {
    width: 65px;
    height: 120px;
    opacity: 1.0;
  }
  100% {
    width:32px;
    height: 60px;
    opacity: 1.0;
  }
}

@keyframes LogoSize {
  0% {
    width:65px;
    height: 120px;
  }
  75% {
    width: 65px;
    height: 120px;
  }
  100% {
    width:32px;
    height: 60px;
  }
}

.AppLogo {
  width: 65px;
  height: 120px;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left:-18px;
  margin-top: 0px;
  animation: 3s ease-out 0s 1 slideTop;
}

.Logo {
  width: 32px;
  height: 60px;
  position: absolute;
  top: 0px;
  left: 0px;
  animation: 3s ease-out 0s 1 LogoSize;
}

.LogoShadow {
  width: 32px;
  height: 60px;
  position: absolute;
  top: 0px;
  left: 0px;
  animation: 3s ease-out 0s 1 OpacityLogo;
}

.Text {
  padding-top: 20px;
  font-family: Verdana;
  font-size: 20px;
  color: #4A4A4A;
  letter-spacing: 1.67px;
  text-align: center;
}

.TextContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.SignUpContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.TextSmall {
  padding-top: 20px;
  font-family: Verdana;
  font-size: 12px;
  color: #4A4A4A;
  letter-spacing: 1px;
  text-align: center;
}

.SkipText {
  display: block;
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #2100F2;
}

.SkipText:hover {
  opacity: 0.5;
  
}