.SignUp {
  position: absolute;
  top: 50%;
  left:50%;
  margin-top: -150px;
  margin-left: -155px;
}

.SignUpTitle {
  font-family: Verdana;
  font-size: 18px;
  color: #4A4A4A;
  letter-spacing: 3.5px;
  text-align: center;
  padding:34px;
}

.SignUpText {
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-shadow: inset 0 0 5px 0 rgba(0,0,0,0.50);
  border-radius: 34px;
  font-family: Verdana;
  font-size: 18px;
  color: #8E8E93;
  letter-spacing: 2px;
  text-align: center;
  width: 275px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.SignUpButton {
  background: #2100F2;
  border: 1px solid #E4E4E4;
  border-radius: 34px;
  font-family: Verdana;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 2px;
  text-align: center;
  padding:8px;
  width: 275px;
  margin-top: 15px;
  cursor: pointer;
}


.SignUpButton:hover {
  background: #12017F;
}